// store.js
import { reactive } from 'vue'

import Proyectos from '@/services/Proyectos';
import { useRouter } from 'vue-router';

export const state = reactive({});
let router = null;

export const initCommonState = async (force) => {
    if (!router) {
        router = useRouter();
    }
    await initEmpresa(force);
    await initProyecto(force);
    // if(force || state.proyectos == undefined){
    //     try{

    //         let proyectos = await Proyectos.getProyectos();
    //         let empresas = await Proyectos.getEmpresas();
    //         state.proyectos = proyectos;
    //         state.empresas=empresas;
            
    //     } catch (err){
    //         console.error(err)
    //     }
    // }
    // return state;
}

export const initEmpresa = async (force) => {
    if ((force || !state.empresa) && router.currentRoute.value.params.empresa) {
        state.empresa = await Proyectos.getEmpresa(router.currentRoute.value.params.empresa)
    }
}
export const initProyecto = async (force) => {
    if ((force || !state.proyecto) && router.currentRoute.value.params.proyecto) {
        state.proyecto = await Proyectos.getProyecto(router.currentRoute.value.params.proyecto);
        initCapasProyecto();
    }
}
export const initCapasProyecto = async (force) => {
    if (state.proyecto && (force || !state.proyecto?.capas)) {
        state.proyecto.capas = await Proyectos.getCapasProyecto(state.proyecto.id);
        state.proyecto?.capas?.forEach(async (capa) => {
            const datosCapa = await Proyectos.getCapa(state.proyecto.id, capa.id);
            capa.condiciones_tematicos = datosCapa.condiciones_tematicos;
            capa.documentos = datosCapa.documentos;
            if (state.proyecto.tipo == 'GEOGRAFICO') {
                const camposCapa = await Proyectos.getCamposCapa(state.proyecto.id, capa.id);
                capa.atributos = camposCapa;
            }
        });
    }
    if (state.proyecto && state.proyecto.tipo == 'ORGANIZATIVO' && (force || !state.proyecto?.actuaciones)) {
        state.proyecto.actuaciones = await Proyectos.getActuaciones(state.proyecto.id, -1);
        state.proyecto.actuaciones?.forEach(async (actuacion) => {
            const campos = await Proyectos.getCamposActuacion(state.proyecto.id, -1, actuacion.id);
            actuacion.campos = campos;
        });
    }
}
