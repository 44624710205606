import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/main.css';
import './theme/main.scss';

import OpenLayersMap from "vue3-openlayers";
// import "vue3-openlayers/styles.css"; // vue3-openlayers version < 1.0.0-*

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import Auth from './services/Auth';

/*  Language Options   */

import { languages } from './locales/index.js';
import { defaultLocale } from './locales/index.js';

const messages = Object.assign(languages);

import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: 'es',
  messages,
  legacy: false
  // something vue-i18n options here ...
})

const securityClass = 'security-hidden';

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(Vue3ColorPicker)
  .use(OpenLayersMap)
  .use(i18n);

router.isReady().then(() => {
  app.mount('#app');
});

const adminAnyCompanyDirective = (el, binding, vnode) => {
  if (!Auth.isAdminAnyCompany()) {
    vnode.el.classList.add(securityClass)
  } else {
    vnode.el.classList.remove(securityClass)
  }
}

const adminCompanyDirective = (el, binding, vnode) => {
  if (!Auth.isAdminCompany(binding.value)) {
    vnode.el.classList.add(securityClass)
  } else {
    vnode.el.classList.remove(securityClass)
  }
}

const admin = (el, binding, vnode) => {
  if (!Auth.isAdmin()) {
    vnode.el.classList.add(securityClass)
  } else {
    vnode.el.classList.remove(securityClass)
  }
}

const permission = (el, binding, vnode) => {
  if (!Auth.permission(binding.value) && vnode.el.parentNode) {
    vnode.el.classList.add(securityClass)
  } else {
    vnode.el.classList.remove(securityClass)
  }
}

const authSrc = (el, binding) => {
  if (binding.value && (binding.oldValue === undefined || binding.value !== binding.oldValue)) {
    el.src = Auth.prepareImageUrl(binding.value);
  }
}


app.directive('admin-any-company', adminAnyCompanyDirective)
app.directive('admin-company', adminCompanyDirective)
app.directive('admin', admin)
app.directive('permission', permission)
app.directive('auth-src', authSrc)
