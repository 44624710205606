import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const Proyectos = {
    getProyectos: async () => {
        const url = baseUrl + `/proyectos`;
        return axios.get(url);
    },
    getProyecto: async (idProyecto) => {
        const url = baseUrl + `/proyectos/${idProyecto}`;
        return axios.get(url);
    },
    getCapasProyecto: async (idProyecto) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas`;
        return axios.get(url);
    },
    getCapa: async (idProyecto, idCapa) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}`;
        return axios.get(url);
    },
    createCapa: async(idProyecto,capa)=>{
        const url=baseUrl+ `/proyectos/${idProyecto}/capas`;
        return axios.post(url,capa)
    },
    getCamposCapa: async (idProyecto, idCapa) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}/campos`;
        return axios.get(url);
    },
    saveCapa: async (idProyecto, capa) => {
        if(capa.id){
            const url = baseUrl + `/proyectos/${idProyecto}/capas/${capa.id}`;
            return axios.put(url, capa);
        }
        const url = baseUrl + `/proyectos/${idProyecto}/capas/`;
        return axios.post(url, capa);
    },
    deleteCapa: async (idProyecto, idCapa) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}`;
        return axios.delete(url);
    },
    save: async (proyecto) => {
        const url = baseUrl + `/proyectos/${proyecto.id}`;
        return axios.put(url, proyecto);
    },
    getFeature: async (idProyecto, idCapa, idFeature) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}/features/${idFeature}`;
        return axios.get(url);
    },
    saveFeature: async (idProyecto, idCapa, feature) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}/features`;
        return axios.post(url, feature);
    },
    deleteFeature: async (idProyecto, idCapa, idFeature) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}/features/${idFeature}`;
        return axios.delete(url);
    },
    getInspeccionesByFeature: async (idProyecto, idCapa, idActuacion, idFeature) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}/actuaciones/${idActuacion}/inspecciones/feature/${idFeature}`;
        return axios.get(url);
    },
    getInspecciones: async (idProyecto, idCapa, idActuacion) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}/actuaciones/${idActuacion}/inspecciones`;
        return axios.get(url);
    },
    getInspeccion: async (idProyecto, idCapa, idActuacion, idInspeccion) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}/actuaciones/${idActuacion}/inspecciones/${idInspeccion}`;
        return axios.get(url);
    },
    saveInspeccion: async (idProyecto, idCapa, idActuacion, inspeccion) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}/actuaciones/${idActuacion}/inspecciones`;
        return axios.post(url, inspeccion);
    },
    getActuaciones: async(idProyecto, idCapa) => {
        const url= baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}/actuaciones/`;
        return axios.get(url);
    },
    getCamposActuacion: async (idProyecto, idCapa, idActuacion) => {
        const url = baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}/actuaciones/${idActuacion}/campos`;
        return axios.get(url);
    },
    saveActuacion: async(idProyecto,idCapa,actuacion) => {
        const url= baseUrl + `/proyectos/${idProyecto}/capas/${idCapa}/actuaciones/`;
        return axios.post(url,actuacion);
    },
    getEmpresas: async() =>{
        const url= baseUrl + `/empresas`;
        return axios.get(url);
    },
    getEmpresaByAlias: async(alias) =>{
        const url= baseUrl + `/empresas/alias/${alias}`;
        return axios.get(url);
    },
    getEmpresa: async (idEmpresa) =>{
        const url = baseUrl + `/empresas/${idEmpresa}`;
        return axios.get(url);
    },
    getUsuariosEmpresa: async(idEmpresa) =>{
        const url = baseUrl + `/empresas/${idEmpresa}/usuarios`;
        return axios.get(url);
    },
    getRolesEmpresa: async(idEmpresa) => {
        const url = baseUrl + `/empresas/${idEmpresa}/roles`
        return axios.get(url);
    },
    createProyecto: async (proyecto) =>{
        const url = baseUrl + `/proyectos`
        return axios.post(url,proyecto);
    },
    getUsuarioEmpresa:async(idEmpresa,idUsuario)=>{
        const url = baseUrl+`/empresas/${idEmpresa}/usuarios/${idUsuario}`
        return axios.get(url);
    },
    // Si pasamos un id capa lo dejará ya en la carpeta de ese id capa.
    // Si le pasamos un idDoc, sobre escribirá el documento en el id que le pasemos
    uploadDocument: async (idProyecto, tipoRelacion, idRelacion, documento, idCapa = undefined, idDoc = undefined) => {
        const url = baseUrl + `/proyectos/${idProyecto}/documentos/${tipoRelacion}/${idRelacion}/`;
        var formData = new FormData();
        formData.append("documento", documento);
        if (idCapa) {
            formData.append("idCapa", idCapa);
        }
        if (idDoc) {
            formData.append("idDoc", idDoc);
        }
        return axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteDocument: async (idProyecto, idDoc) => {
        const url = baseUrl + `/proyectos/${idProyecto}/documentos/${idDoc}`;
        return axios.delete(url);
    },
    getProyectosEmpresa: async(idEmpresa)=>{
        const url=baseUrl+`/proyectos/${idEmpresa}/proyectosEmpresa`
        return axios.get(url);
    },
    addRolToUser: async(idProyecto,idUsuario,idRol)=>{
        const url=baseUrl+`/proyectos/${idProyecto}/usuarios/${idUsuario}/roles/${idRol}`
        return axios.post(url);
    },
    deleteRolToUser: async(idProyecto,idUsuario)=>{
        const url=baseUrl+`/proyectos/${idProyecto}/usuarios/${idUsuario}/roles`
        return axios.delete(url);
    },
    createUsuario: async(idEmpresa,usuario)=>{
        const url=baseUrl+`/empresas/${idEmpresa}/usuarios`
        return axios.post(url,usuario);
    },
    deleteUsuario: async(idEmpresa,idUsuario)=>{
        const url=baseUrl+`/empresas/${idEmpresa}/usuarios/${idUsuario}`
        return axios.delete(url);  
    },
    createEmpresaRol: async(idEmpresa,rol)=>{
        const url=baseUrl+`/empresas/${idEmpresa}/roles/`
        return axios.post(url,rol);
    },
    deleteRolEmpresa: async(idEmpresa,idRol)=>{
        const url=baseUrl+`/empresas/${idEmpresa}/roles/${idRol}`
        return axios.delete(url);
    },
    getPermisos: async(idEmpresa)=>{
        const url=baseUrl+`/empresas/${idEmpresa}/permisos`
        return axios.get(url);
    },
    saveRol: async(idEmpresa,idRol,rol)=>{
        const url=baseUrl+`/empresas/${idEmpresa}/roles/${idRol}`
        return axios.put(url,rol);
    },
    updateUsuario:async(idEmpresa,idUsuario,usuario)=>{
        const url=baseUrl+`/empresas/${idEmpresa}/usuarios/${idUsuario}`;
        return axios.put(url,usuario);
    },
    getMe:async()=>{
        const url=baseUrl+`/me`;
        return axios.get(url);
    },
    deleteCapa:async(idProyecto,idCapa)=>{
        const url=baseUrl+`/proyectos/${idProyecto}/capas/${idCapa}`;
        return axios.delete(url)
    },
    deleteProyecto:async(idProyecto)=>{
        const url=baseUrl+`/proyectos/${idProyecto}`;
        return axios.delete(url);
    }
}

export default Proyectos;
