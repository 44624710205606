import es from './es.json'
import en from './en.json'
import ca from './ca.json'
import gl from './gl.json'
import eu from './eu.json'


export const defaultLocale = 'es'

export const languages = {
  es,
  en,
  ca,
  gl,
  eu,
}