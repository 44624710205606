import { createRouter, createWebHistory } from '@ionic/vue-router';

import Auth from '@/services/Auth'

import LoginPage from '@/views/LoginPage.vue'

const routes = [
  {
    path:'/',
    component:LoginPage,
    meta: {
      title: 'Inicio de sesión',
      breadcrumb: {
        hidden: true
      }
    },
    segment:'empresas'
  },
  {
    path: '/login',
    component: LoginPage,
    meta: {
      title: 'Inicio de sesión',
      breadcrumb: {
        hidden: true
      },
      segment:'empresas'
    }
  },
  {
    path: '/logout',
    component: LoginPage,
    props: { logout: true },
    meta:{
      breadcrumb: {
        hidden: true
      }
    }
  
  },
  {
    path: '/empresas',
    component: () => import('@/views/EmpresasPage.vue'),
    name: 'empresas',
    meta: {
      breadcrumb: {
        name: "'Empresas'"
      },
      segment:'empresas'
    }
  },
  {
    path: '/empresas/:empresa',
    component: () => import('@/views/EmpresaPage.vue'),
    name: 'detalle-empresa',
    meta: {
      breadcrumb: {
        parent: 'empresas',
        name: 'state.empresa.nombre'
      },
      segment:'empresas'
    }
  },
  {
    path: '/empresas/:empresa/proyectos',
    component: () => import('@/views/ProyectosPage.vue'),
    name: 'proyectos-empresa',
    meta: {
      breadcrumb: {
        parent: 'detalle-empresa',
        name: "'Proyectos'"
      },
      segment:'empresas'
    }
  },
  {
    path: '/empresas/:empresa/proyectos/:proyecto',
    component: () => import('@/views/ProyectoPage.vue'),
    name: 'detalle-proyecto',
    meta: {
      breadcrumb: {
        parent: 'proyectos-empresa',
        name: 'state.proyecto.nombre'
      },
      segment:'empresas'
    }
  },
  {
    path: '/empresas/:empresa/usuarios',
    component: () => import('@/views/Usuarios.vue'),
    name: 'usuarios-empresa',
    meta: {
      breadcrumb: {
        parent: 'detalle-empresa',
        name:"'Usuarios'"
      },
      segment:'empresas'
    }
  },
  {
    path: '/empresas/:empresa/usuarios/:idUsuario',
    component: () => import('@/views/Usuario.vue'),
    name: 'detalle-usuario',
    meta: {
      breadcrumb: {
        parent: 'usuarios-empresa',
        name:'state.usuarioSeleccionado.nombre'
      },
      segment:'empresas'
    }
  },
  {
    path: '/empresas/:empresa/roles',
    component: () => import('@/views/Roles.vue'),
    name: 'roles-empresa',
    meta: {
      breadcrumb: {
        parent: 'detalle-empresa',
        name:"'Roles'"
      },
      segment:'empresas'
    }
  },
  {
    path: '/empresas/:empresa/roles/:aliasRol',
    component: () => import('@/views/Rol.vue'),
    name: 'detalle-rol',
    meta: {
      breadcrumb: {
        parent: 'roles-empresa',
        name:'state.rolSeleccionado.nombre'
      },
      segment:'empresas'
    }
  },
  {
    path: '/inspecciones',
    name: 'inspecciones',
    component: () => import('@/views/InspeccionesPage.vue'),
    meta: {
      cabecera: 'proyecto',
      breadcrumb: {
        hidden: true
      },
      segment: 'inspecciones'
    }
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('@/views/PerfilPage.vue'),
    meta: {
      title: 'Perfil',
      breadcrumb: {
        hidden: true
      },
      segment:'perfil'
    }
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {

  Auth.initAuth();
  
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/logout'];
  const authRequired = !publicPages.includes(to.path);
  const loginData = Auth.getUsuarioLogado();
  
  if (authRequired) {
    if(loginData == null || !loginData.me || !loginData.me.id) {
      console.error(`Sesión inválida. Redirigiendo a /login`);
      return '/login';
    }
  }
});

export default router
