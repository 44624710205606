<template>
  <ion-page>
    <template v-if="!logout">
      <ion-content :fullscreen="true">
        <ion-img
          class="img-logo"
          :src="logoD"
          @click="__resetCosas"
        ></ion-img>
        <form class="login-form" @submit.prevent="login(false)">
          <ion-list>
            <ion-item>
              <ion-input class="login-input" label-placement="floating" :label="$t('Usuario')" v-model="usuario"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input class="login-input" label-placement="floating" :label="$t('Contraseña')" v-model="password" type="password"></ion-input>
            </ion-item>
          </ion-list>
          <ion-button class="login-btn" type="submit">{{ $t('Entrar') }}</ion-button>
        </form>
      </ion-content>
      <ion-footer class="login-footer">
        <ion-item
          color="primary"
          v-if="showBiometric"
          @click="loginFromBiometric"
        >
          <ion-img
            :src="(biometricAvailable == BiometryType.FACE_ID || biometricAvailable == BiometryType.FACE_AUTHENTICATION) ? faceIdIcon : fingerPrintIcon"
            class="img-biometric"
          ></ion-img>
          <ion-label class="label-footer">
            {{(biometricAvailable == BiometryType.FACE_ID || biometricAvailable == BiometryType.FACE_AUTHENTICATION) ? 'Reconocimiento facial' : 'Acceso por huella'}}
          </ion-label>
        </ion-item>
      </ion-footer>
    </template>
  </ion-page>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { IonButton, IonInput, IonItem, IonList, IonPage, IonImg, IonContent, IonFooter, IonLabel } from '@ionic/vue';

import { NativeBiometric, BiometryType } from "@capgo/capacitor-native-biometric";

import faceIdIcon from "@/assets/img/faceid.png";
import fingerPrintIcon from "@/assets/img/fingerprint.png";
import logoD from "@/assets/img/logo-d.png";

import Auth from '@/services/Auth';
import Biom from '@/services/Biom';
import { showDialog } from '@/services/Utils';

import { loginData } from '@/stores/loginData';
import { useI18n } from 'vue-i18n';

const { t } = useI18n({ useScope: 'global' });


const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const PAGINA_ENTRADA = "/empresas";

/* Data */
const usuario = ref('');
const password = ref('');
const errores = reactive({});

const router = useRouter();

const biometricAvailable = ref(false);

/* Computed */
const showBiometric = computed(() => {
  return biometricAvailable.value && Biom.getBiometricoActivado();
});

/* Properties */
const props = defineProps({
  logout:  {
    type: Boolean,
    default: false
  },
});

/* Methods */


const loginFromBiometric = async () => {
  // Para llegar aquí tiene que estar disponible el biométrico, y haber sido activado para el usuario.

  // Verificamos la cara de la persona
  const verified = await NativeBiometric.verifyIdentity({
    reason: "Inicio de sesión biométrico",
    title: "Inicio de sesión",
  })
    .then(() => true)
    .catch(() => false);

  // Si ha fallado la verificación, terminamos
  if (!verified) {
    return;
  }

  // Ha fincionado la verificación, vamos a recuperar sus credenciales
  const credentials = await NativeBiometric.getCredentials({ server: BASE_URL });

  // Si no encontramos credenciales del usuario, terminamos
  if (!credentials) {
    return;
  }

  // Tenemos credenciales, las ponemos en los input y lanzamos el login (indicando que venimos de biométricos)
  usuario.value = credentials.username;
  password.value = credentials.password;
  login(true)
}

const login = async (fromBiometric = false) => {

  // Comprobamos que el usuario está relleno
  if(usuario.value.trim() == ""){
    errores.value.usuario = true;
  }
  try{
    // Comprobamos credenciales
    let loginResponse = await Auth.login(usuario.value, password.value);

    // Si no están bien, terminamos
    if(!loginResponse){
      console.error("error en login");
      return;
    }

    // Si no venimos del biométrico hacer lógica de si es necesario configurarlo
    if (!fromBiometric) {
      await popupConfiguracionBiometrico(usuario.value, password.value);
    }

    // Guardar credenciales de ser necesario
    almacenarCredenciales(usuario.value, password.value);

    // Por último, vamos a la página inicial
    await router.go(PAGINA_ENTRADA);
  } catch(e){ /* empty */ }
}

const popupConfiguracionBiometrico = async (username, password) => {
  // Comprobamos que está disponible el acceso biométrico
  if (!biometricAvailable.value) return;

  // Comprobamos que no esté ya configurado
  if(Biom.getBiomConfigurado()) return;

  // Comprobamos que tengamos datos de credenciales para guardar en la configuración
  if (!username || !password) return;

  // Todo ok. Preguntamos que si quiere usar los datos biométricos
  const resultAlert = await showDialog(
    t('Activación reconocimiento biométrico'),
    t('¿Quiere activar el reconocimiento biométrico para acceder a la aplicación?'),[
      { text: t('No'), role: 'no' },
      { text: t('Si'), role: 'si' },
    ]
  );
  
  // Guardamos la respuesta
  const biometricoAceptado = resultAlert.role == 'si';

  Biom.activarBiometrico(biometricoAceptado);

  // Si no queremos usarlos, hemos terminado
  if (!biometricoAceptado) return;

  // Si quiere usar los datos biométricos, tomamos su cara para asociarla a las credenciales
  const verified = await NativeBiometric.verifyIdentity({
    reason: "Inicio de sesión biométrico",
    title: "Inicio de sesión",
  })
    .then(() => true)
    .catch(() => false);

  // Si la cara no era la correcta, terminamos
  if (!verified) return; // TODO ¿Controlar error?

  // Guardamos las credenciales
  const credencialesBiometricas = {  // TODO Extraer a lugar comun
    username: username,
    password: password,
    server: BASE_URL,
  };
  Auth.actualizarSesion("credencialesBiometricas", credencialesBiometricas);

  // Las asociamos a los datos biométricos
  await NativeBiometric.setCredentials(credencialesBiometricas).then(() => true)
    .catch(() => false); // TODO Controlar error
}

const almacenarCredenciales = (username, password) => {
  // Si hay biométrico, guarda las credenciales por si en el futuro lo quiere volver a activar

  // Comprobamos que está disponible el acceso biométrico
  if (!biometricAvailable.value) return;

  // Recuperamos las credenciales
  const credencialesBiometricas = {  // TODO Extraer a lugar comun
    username: username,
    password: password,
    server: BASE_URL,
  };

  // Las guardamos
  loginData.credencialesBiometricas = credencialesBiometricas;
}

const initLogin = async () => {
  // Lógica a ejecutar al llegar a la pantalla

  // Si se ha activado la property logout, hacemos logout
  const logout = props.logout;
  if(logout){
    try{
      // Auth.deleteAuthData();
      await Auth.logout();
    } catch (err) {
      if(err?.response?.status == 401) {
        // El servidor ya considera que está acabada la sesión
        console.info("La sesión ya estaba caducada");
      } else {
        console.error("Error al hacer logout", err);
      }
    }
    // Terminado el logout, marcamos como que entramos a login
    await router.replace("/login");
  }

  // Si ya tenemos una sesión activa hacemos login automáticamente
  let loginData = Auth.getUsuarioLogado(),
    id = loginData != null ? loginData.me.id : '';
  if (id){
    console.debug("Navegando a /")
    await router.push(PAGINA_ENTRADA);
    return;
  }

  // Preparamos pantalla de login
  try{
    // Comprobamos si tenemos funcionalidades de biométricos
    const resultAvailable = await NativeBiometric.isAvailable();
    biometricAvailable.value = resultAvailable.isAvailable ? resultAvailable.biometryType : resultAvailable.isAvailable;
  } catch {
    console.info("No hay sistema de datos biométricos disponible.");
  }

  // Comprobamos si tenemos que hacer login automáticamente si se cumplen todas las condiciones
  //  - No venimos de logout (!logout)
  //  - Tenemos capacidades biométricas
  //  - El usuario configuró que quería login biométrico
  const bioActivado = Biom.getBiometricoActivado();
  if(!logout && biometricAvailable.value && bioActivado) {
    await loginFromBiometric();
  }
  
}

onMounted(async () => {await initLogin()});

// Funcion debug para resetear el estado de las configuraciones para emular que se llega por primera vez a la pantalla
const __resetCosas = () => { // TODO ELIMINAR
  Biom.__resetDatosBiometrico();
}

</script>

<style scoped>
.login-form {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  padding: 5vh 5vw 5vh 5vw;
  border: solid 1px var(--ion-color-primary);
  border-radius: 5vh;
}
.login-footer {
  min-height: 5vh;
  background-color: var(--ion-color-primary);
}
/* .login-input {
  text-align: center;
  width: 80vw;
} */
.error {
  background: red;
}
.img-logo {
  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto; 
  height: 30vh;
}
.img-biometric {
  height: 7vh;
  padding: 0.5vh;
  padding-left: 7vw;
}
.label-footer {
  font-size: small;
  margin-left: 0.5vw;
}
</style>
