
import { alertController } from '@ionic/vue';
export const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export const showDialog = async (title, message, buttons, inputs = []) => {
    const alert = await alertController.create({
      header: title,
      message: message,
      buttons: buttons,
      inputs: inputs,
    });

    await alert.present();
    const resultAlert = await alert.onDidDismiss();
    return resultAlert;
}


// OpelLayers
import Feature from "ol/Feature";
import Icon from 'ol/style/Icon.js';
import { MultiPoint, MultiLineString, MultiPolygon } from "ol/geom";
export const featureFromGeom = (feat) => {
    let geometry,
        geom = feat.geom;
    if (!geom) {
        return feat;
    }
    switch (geom.type){
      case "MultiPoint":
        geometry = new MultiPoint(geom.coordinates);
        break;
      case "MultiLineString":
        geometry = new MultiLineString(geom.coordinates);
        break;
      case "MultiPolygon":
        geometry = new MultiPolygon(geom.coordinates);
        break;
    }
    return new Feature(Object.assign(feat, { geom: undefined, geometry}));
}
export const iconFromUrl = (urlImage) => {
    return new Icon({
        anchor: [0.5, 36], 
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        opacity: 1,
        src: urlImage,  
        zIndex: 1,
        width: 10
    });
}

export const getTransp = (color) => {
  let matchesRgba= color.replace(/\s+/g, '').replace(/rgba\((.*)\)/g,"$1");
  if(matchesRgba){
    let colors = matchesRgba.split(",");
    return color[3] && colors[3] < 1 ? colors[3] * 100 : "";
  }

  let matchesHex= color.replace(/\s+/g, '').match(/#......(\d\d)/g);
  if(matchesHex[1]){
    return matchesHex[1];
  }
}