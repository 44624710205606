import { loginData } from '@/stores/loginData'
import { state } from '@/stores/state'
import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_BASE_URL;
const axiosAux = axios.create(); // Para evitar los interceptores por defecto en estas llamadas

const Auth = {
    interceptorInicializado: false,
    isAdminAnyCompany: () => {
        return loginData.me.isAdmin || (loginData.me.empresasAdmin && loginData.me.empresasAdmin.length);
    },
    isAdmin: () => {
        return loginData.me.isAdmin;
    },
    isAdminCompany: (idEmpresa) => {
        return loginData.me.empresasAdmin.includes(idEmpresa);
    },
    permission: (permissions) => {
        if (Auth.isAdmin()) {
            return true
        }
        if (typeof permissions == 'string') {
            permissions = [permissions];
        }
        if (!Array.isArray(permissions)) {
            return false;
        }
        const proyecto = state.proyecto;
        if (!proyecto || !proyecto.id) {
            return false;
        }
        if (Auth.isAdminCompany(proyecto.id_empresa)) {
            return true
        }
        const permisosRoles = loginData.me?.roles
            .filter(er => er.idProyecto === proyecto.id)
            .flatMap(er => er.permisos.filter(p => permissions.includes(p.nombre)))
        if (permisosRoles.length) {
            return true
        }
        return false
    },
    getUsuarioLogado: () => {
        return loginData && loginData.me ? loginData : null;
    },
    actualizarSesion: (campo, valor) => {
        loginData[campo] = valor;
    },
    login: async (usuario, password) => {
        const urlLogin = baseUrl + "/login";
        const params = {
            "username": usuario,
            "password": password
        }
        let tokenData = await axios.post(urlLogin, params);
        if(!tokenData || !tokenData.token){
            return null;
        }
        const authorization = `${tokenData.type} ${tokenData.token}`;
        axios.defaults.headers.common['Authorization'] = authorization;
        
        const userData = await Auth.me(authorization);

        Auth._updateTokens(userData, tokenData);
        
        Auth._loadUserState(userData);
        return userData;
    },
    updatePassword: async (usuario, oldPassword,newPassword) => {
        const params = {
            "username": usuario,
            "password": oldPassword,
            "newPassword":newPassword
        }
        const url= baseUrl + "/updatePassword"
        let tokenData = await axios.put(url, params);
        if(!tokenData || !tokenData.token){
            return null;
        }
        const authorization = `${tokenData.type} ${tokenData.token}`;
        axios.defaults.headers.common['Authorization'] = authorization;
        
        const userData = await Auth.me(authorization);

        Auth._updateTokens(userData, tokenData);
        
        Auth._loadUserState(userData);
        return userData;
    },
    me: async (authorization) => {
        const urlMe = baseUrl + "/me";
        axios.defaults.headers.common['Authorization'] = authorization;
        const userData = await axios.get(urlMe);
        return userData;
    },
    refreshSession: async () => {
        // Llamar a refresh token
        const authData = Auth._getAuthData(),
            urlRefresh = baseUrl + "/refreshToken";
        if(!authData.refreshToken){
            return;
        }
        try{
            const params = {"refresh_token": authData.refreshToken},
                tokenResponse = await axiosAux({
                        method: "post",
                        url: urlRefresh,
                        data: params,
                        __isRetryRequest: true,
                        __hideDefaultError: true
                    }),
                tokenData = tokenResponse?.data;

            // Guardar nuevos token
            if(tokenData){
                const userData = Auth._getAuthData();
                Auth._updateTokens(userData, tokenData);
            }
            const authorization = `${tokenData.type} ${tokenData.token}`.trim();

            return authorization;
        } catch (error){
            console.error("Error refrescando token", error);
            return;
        }
    },
    logout: async () => {
        const urlLogout = baseUrl + "/logout";
        console.log("logout", axios.defaults.headers.common.Authorization);

        await axios({
            method: "post",
            url: urlLogout,
            __isRetryRequest: true, // Indica al interceptor que no debe intentar refrescar token si está caducado
            __hideDefaultError: true // No mostramos los errores que ocurran al hacer logout
        });

        Auth.deleteAuthData();
    },
    initAuth: () => {
        const authData = Auth._getAuthData();
        if(authData){
            axios.defaults.headers.common['Authorization'] = authData.authorization;
            delete authData.authorization;
            delete authData.refreshToken;
            Auth._loadUserState(authData);
        }
        if(!Auth.interceptorInicializado){
            Auth.interceptorInicializado = true;
            axios.interceptors.response.use(res => res, async err => {
                let res = err.response;
                if (res.status == 401 && res.config && !res.config.__isRetryRequest) {
                    const newAuthorization = await Auth.refreshSession();
                    if(newAuthorization){
                        err.config.__isRetryRequest = true;
                        err.config.validateStatus = false;
                        err.config.headers.Authorization = newAuthorization;
                        const axiosAux = axios.create();
                        return axiosAux.request(err.config);
                    }
                }
                return Promise.reject(err);
            });
        }
    },
    checkTokens: async () => {
        // Intenta refrescar la información de me con el token almacenado si lo hay, para comprobar si sigue activo
        // Devuelve true si todo ha ido bien, false en caso contrario
        const authData = Auth._getAuthData();
        if(!authData.authorization) return false;

        await Auth.me(authData.authorization);

    },
    prepareImageUrl: (imageUrl) => {
        if (!imageUrl || imageUrl.startsWith('blob:')) {
            return imageUrl;
        }
        if (!imageUrl.startsWith('http')) {
            imageUrl = baseUrl + imageUrl;
        }
        if (axios.defaults.headers.common['Authorization']) {
            imageUrl = imageUrl + '?access_token=' + axios.defaults.headers.common['Authorization'].split(' ')[1]
        }
        return imageUrl;
    },
    _updateTokens: (userData, tokenData) => {
        const authorization = `${tokenData.type} ${tokenData.token}`;
        userData.authorization = authorization;
        userData.refreshToken = tokenData.refreshToken;
        axios.defaults.headers.common['Authorization'] = authorization;
        Auth._saveAuthData(userData);
    },
    _loadUserState: (userData) => {
        Auth.actualizarSesion("me", userData);
        Auth.actualizarSesion("id", userData.id);
    },
    _saveAuthData: (data) => {
        let dataAux = Object.assign({}, data);
        delete dataAux._completeResponse;
        localStorage.setItem("auth", JSON.stringify(dataAux));
    },
    _getAuthData: () => {
        return JSON.parse(localStorage.getItem("auth"));
    },
    deleteAuthData: () => {
        localStorage.removeItem("auth");
        delete axios.defaults.headers.common.Authorization;
        delete loginData.me;
        delete loginData.id;
    }
}

export default Auth;
