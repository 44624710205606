<template>
    <cabecera-proyecto v-if="cabecera == 'proyecto'"></cabecera-proyecto>
    <ion-header id="header" v-else>
      <ion-toolbar>
        <ion-title v-if="title">{{ $t(title) }}</ion-title>
      </ion-toolbar>
    </ion-header>
  </template>
  
<script setup>
import { IonHeader, IonToolbar, IonTitle } from '@ionic/vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import CabeceraProyecto from './CabeceraProyecto.vue';
  
const router = useRouter();

const cabecera = computed(() => router.currentRoute.value.matched[0]?.meta?.cabecera);
const title = computed(() => router.currentRoute.value.matched[0]?.meta?.title);
  
</script>
  