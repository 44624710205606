
import { reactive } from 'vue';


const Biom = {
    BIOM_CONFIG_LOCALSTORAGE_ITEM: "biometricoActivado",
    biomState: reactive({
        activo: false
    }),
    activarBiometrico: (activado) => {
        localStorage.setItem(Biom.BIOM_CONFIG_LOCALSTORAGE_ITEM, activado);
        Biom.biomState.activo = activado;
    },
    getBiomConfigurado: () => {
        const biomConfigurado = JSON.parse(localStorage.getItem(Biom.BIOM_CONFIG_LOCALSTORAGE_ITEM)) != null;
        return biomConfigurado;
    },
    getBiometricoActivado: () => {
        const biomActivado = !!JSON.parse(localStorage.getItem(Biom.BIOM_CONFIG_LOCALSTORAGE_ITEM));
        Biom.biomState.activado = biomActivado;
        return Biom.biomState.activado;
    },
    __resetDatosBiometrico: () => {
        if(process.env.NODE_ENV == "development"){
            console.debug("[BIOM] Reseteados los datos biométricos");
            Biom.biomState.activo = false;
            localStorage.removeItem(Biom.BIOM_CONFIG_LOCALSTORAGE_ITEM);
        }
    }
}

export default Biom;
